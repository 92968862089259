// LandingPage.js

import React, { useRef, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Box, Typography, Button, Snackbar, Alert, CircularProgress, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'; // Import Retry Icon
import config from '../config';
import ConfettiExplosion from 'react-confetti-explosion'; // Import ConfettiExplosion component
import './LandingPage.css';

const exampleImages = [
  '/example_images/human2.png',
  '/example_images/jip3.png',
  '/example_images/dogs2.png',
  '/example_images/elephant3.png',
  '/example_images/farm2.png',
  '/example_images/polarbears2.png',
  '/example_images/car2.png',
  '/example_images/pumpkin2.png',
  '/example_images/liberty2.png',
];

function LandingPage({ user, addToCart, onLogin, fetchCartItems }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [convertedPreview, setConvertedPreview] = useState(null);
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [retrying, setRetrying] = useState(false); // State for retrying action
  const [isExploding, setIsExploding] = useState(false); // State for confetti explosion
  const [cartItems, setCartItems] = useState([]); // Added cartItems state
  const [watermarkedPreview, setWatermarkedPreview] = useState(null);
  const [inCart, setInCart] = useState(false); // New state to track if the current image is in the cart
  const [showLoadingBubble, setShowLoadingBubble] = useState(false); // State for the loading bubble

  const uploadBoxRef = useRef(null); // Reference to the upload box for positioning the explosion
  const scrollRef = useRef(null);
  const imageWidth = 500;
  const imageMargin = 20;

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.scrollLeft = imageWidth + imageMargin;
    }
  }, [scrollRef]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      console.log('Accepted file type:', file.type);

      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };

      if (file.type === 'image/png' || file.type.startsWith('image/')) {
        reader.readAsDataURL(file);
      } else {
        console.error('Unsupported file type:', file.type);
      }
    } else {
      console.error('No files were accepted.');
    }

    // Reset state when a new file is dropped
    setConvertedPreview(null);
    setWatermarkedPreview(null);
    setInCart(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg, image/jpg, image/gif',
    maxFiles: 1,
    maxSize: 50000000, // 50 MB limit
  });

  const handleRemoveFile = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setPreview(null);
    setConvertedPreview(null);
    setWatermarkedPreview(null);
    setInCart(false); // Reset cart status when file is removed
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!user) {
      onLogin();
      return;
    }
  
    setLoading(true);
    setShowLoadingBubble(true); // Show the loading bubble when loading starts
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('email', user.email); // Add user email
    formData.append('name', user.name); // Add user name
  
    try {
      const response = await axios.post(`${config.backendUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handling the response to set image previews and UI messages
      if (response.data) {
        const newConvertedPreview = `data:image/png;base64,${response.data.coloring_page}`;
        const newWatermarkedPreview = `data:image/png;base64,${response.data.watermarked_image}`;

        setConvertedPreview(newConvertedPreview);
        setWatermarkedPreview(newWatermarkedPreview); // Store the watermarked version too
        setMessage(response.data.message);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setIsExploding(true); // Trigger confetti explosion when upload is successful

        // Reset inCart state since it's a new upload
        setInCart(false);

        // Optionally update local state for cart items or other purposes
        setCartItems((prevItems) => [...prevItems, { image: newConvertedPreview }]);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      setShowLoadingBubble(false); // Hide the loading bubble when loading finishes
    }
  };


  const handleRetry = async () => {
    setRetrying(true); // Set retrying state to true
    setLoading(true); // Also set loading state to true for consistency
    setInCart(false); // Reset cart status on retry
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('email', user.email); // Ensure email is included
    formData.append('name', user.name);   // Ensure name is included
    formData.append('retry', 'true');     // Indicate this is a retry
  
    try {
      const response = await axios.post(`${config.backendUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setConvertedPreview(`data:image/png;base64,${response.data.coloring_page}`);
      setMessage('Successfully retried! Here is your new coloring page.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error retrying file:', error);
      setMessage('Error retrying file');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setRetrying(false); // Reset retrying state to false
      setLoading(false); // Reset loading state to false
    }
  };
  
  useEffect(() => {
    if (isExploding) {
      const timer = setTimeout(() => setIsExploding(false), 3500); // Reset confetti explosion after 3.5 seconds
      return () => clearTimeout(timer);
    }
  }, [isExploding]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddToCart = async () => {
    if (!convertedPreview || !watermarkedPreview) {
      console.log('No image to add.');
      return;
    }
  
    // Disable the "Add to Cart" button immediately
    setInCart(true);
  
    // Add a placeholder item to the cart UI for immediate feedback
    const placeholderItem = {
      id: `temp-${Date.now()}`, // Unique temporary ID
      image_base64: convertedPreview,
      image_base64_watermark: null, // Placeholder loading state
      loading: true, // Indicate that this is a placeholder
    };
  
    // Immediately update the cartItems state to include the placeholder
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems, placeholderItem];
      
      // Save the new cart items to localStorage right away
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
  
      return updatedItems; // Return the updated state for React to re-render immediately
    });
  
    // Function to upload to backend
    const uploadToBackend = async () => {
      try {
        const response = await axios.post(`${config.backendUrl}/add_to_cart`, {
          user_email: user.email,
          coloring_page: convertedPreview.split(',')[1], // Only send the base64 data
          watermarked_image: watermarkedPreview.split(',')[1],
        });
  
        // Update the placeholder with actual data from the backend
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.id === placeholderItem.id
              ? {
                  ...item,
                  loading: false,
                  id: response.data.item_id, // Replace with actual ID from backend
                }
              : item
          )
        );
  
        // Refresh the cart with a fresh fetch from the backend (optional)
        fetchCartItems();
      } catch (error) {
        console.error('Failed to add item to cart:', error);
  
        // Revert the placeholder in case of an error
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.id !== placeholderItem.id)
        );
      }
    };
  
    // Function to save low-scale version locally
    const saveLowScaleVersionLocally = async () => {
      try {
        const canvas = document.createElement('canvas');
        const image = new Image();
  
        // Set the source of the image
        image.src = convertedPreview;
  
        // Wait until the image loads
        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        });
  
        // Set canvas size to a very small scale
        const scale = 0.1; // Scale down to 10% of the original size
        canvas.width = image.width * scale;
        canvas.height = image.height * scale;
  
        // Draw the image to the canvas at a reduced scale
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  
        // Convert the canvas to a base64 string
        const lowScaleBase64 = canvas.toDataURL();
  
        // Save low-scale version to localStorage
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        storedCartItems.push({
          id: placeholderItem.id,
          lowScaleImage: lowScaleBase64,
        });
        localStorage.setItem('cartItems', JSON.stringify(storedCartItems));
      } catch (error) {
        console.error('Failed to create low-scale image:', error);
      }
    };
  
    // Execute both actions asynchronously in parallel
    uploadToBackend(); // Upload the base64 image to the backend (doesn't block UI)
    saveLowScaleVersionLocally(); // Save a low-scale version locally (also non-blocking)
  };
  
  const scrollLeft = () => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.scrollLeft - (imageWidth + imageMargin);
      scrollRef.current.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const scrollAmount = scrollRef.current.scrollLeft + (imageWidth + imageMargin);
  
      if (scrollAmount <= maxScrollLeft) {
        scrollContainer.scrollTo({ left: scrollAmount, behavior: 'smooth' });
      } else {
        scrollContainer.scrollTo({ left: maxScrollLeft, behavior: 'smooth' });
      }
    }
  };
  
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ width: '100%', maxWidth: '800px', mx: 'auto', mt: 2 }}>

      {/* Confetti Explosion */}
      {isExploding && uploadBoxRef.current && (
        <ConfettiExplosion
          particleCount={150}
          duration={3000} // Duration to make the confetti fall longer
          force={0.5} // Force of the initial explosion
          height={800} // Height adjusted to simulate falling to the bottom
          width={600} // Width of the explosion
          zIndex={2} // Ensure it's above other content
          colors={['#FFC700', '#FF0000', '#2E3191', '#41BBC7']} // Custom colors
          style={{
            position: 'absolute',
            top: uploadBoxRef.current.getBoundingClientRect().top + (uploadBoxRef.current.clientHeight / 2), // Center vertically
            left: uploadBoxRef.current.getBoundingClientRect().left + (uploadBoxRef.current.clientWidth / 2), // Center horizontally
            transform: 'translate(-50%, -50%)', // Center at point
          }} // Position relative to the upload box center
        />
      )}

      {/* Upload Box */}
      <Box ref={uploadBoxRef} className="upload-box" width="120%" mb={4} p={2} borderRadius="10px" boxShadow={3} position="relative">
        <form onSubmit={handleSubmit} noValidate>
          <Box
            {...getRootProps()}
            className="dropzone"
          >
            <input {...getInputProps()} />
            {preview ? (
              <>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    zIndex: 10,
                    backgroundColor: 'white',
                    color: '#3a7ea1', // Adjusted to match theme
                    borderRadius: '50%',
                    width: '22px',
                    height: '22px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    }
                  }}
                  onClick={handleRemoveFile}
                >
                  <CloseIcon />
                </IconButton>
                {/* Container for Image and Retry Button */}
                <Box sx={{ width: '100%', height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    src={loading ? preview : watermarkedPreview || preview}
                    alt="Selected file preview"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '300px',
                      filter: loading ? 'blur(4px)' : 'none',
                      display: 'block',
                      margin: 'auto',
                      border: '2px solid white',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)'
                    }}
                  />
                  {loading && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 11,
                      }}
                    >
                      <CircularProgress size={64} />
                    </Box>
                  )}
                  {/* Retry Button Positioned to the Right of the Image */}
                  {convertedPreview && (
                    <IconButton
                      color="default"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the drop zone file selection
                        handleRetry();
                      }}
                      disabled={retrying || loading} // Disable when retrying or loading
                      sx={{
                        position: 'absolute', // Position absolutely within the parent box
                        top: '50%', // Center vertically relative to the image
                        left: 'calc(50% + 300px)', // Position slightly to the right of the image
                        transform: 'translate(-50%, -50%)', // Adjust centering
                        backgroundColor: '#ffffff',
                        borderRadius: '50%',
                        boxShadow: 2,
                        zIndex: 12, // Above other elements
                        '&:hover': { backgroundColor: '#e0e0e0' } // Slightly darker on hover
                      }}
                    >
                      <ReplayOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              </>
            ) : (
              <Box textAlign="center">
                <CloudUploadIcon sx={{ fontSize: 48, color: '#fff' }} />
                <Typography variant="h6" className="dropzone-text">
                  Drag & Drop Files Here or Click to Upload
                </Typography>
              </Box>
            )}
          </Box>
          {convertedPreview ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#4a90e2',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#357ABD' },
                  mt: 2,
                }}
                startIcon={<AddShoppingCartIcon />}
                onClick={handleAddToCart}
                disabled={inCart || !convertedPreview || loading} // Disable button if image is already in cart, loading, or no converted preview
              >
                {inCart ? 'Added to Cart' : 'Add to Cart'}
              </Button>
            </Box>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!selectedFile || loading}
              sx={{ mt: 2 }}
            >
              Convert
            </Button>
          )}
        </form>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>

      {/* Info Box */}
      <Box className="info-box" width="140%" p={4} borderRadius="10px" boxShadow={3} sx={{ backgroundColor: '#ffffff', mt: 4 }}>
        <Box textAlign="center" width="100%">
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ mb: 3,
              fontFamily: 'CupCakes, sans-serif', // Applying the CupCakes font
            }}
          >
          World's best photo to coloring-page converter!
          </Typography>
          <Box 
            sx={{ 
              maxWidth: '80%', // Set the max width of the text box
              mx: 'auto', // Center the text box horizontally
              textAlign: 'center' // Center the text inside the box
            }}
          >
            <Typography variant="body1" color="textSecondary" sx={{ mb: 4,  }}>
              Coloring line art is a great way to relax! With MyColoringPic, you can convert your favorite pictures into fun coloring pages—ideal for family photos, pet pics, or photos of your home.
            </Typography>
          </Box>
          <Box className="scroll-container">
            <Button
              className="scroll-button left"
              onClick={scrollLeft}
            >
              &lt;
            </Button>
            <Box className="scroll-content" ref={scrollRef}>
              {exampleImages.concat(exampleImages).map((src, index) => (
                <Box className="image-wrapper" key={index}>
                  <img src={src} alt={`Example ${index + 1}`} className="example-image" />
                </Box>
              ))}
            </Box>
            <Button
              className="scroll-button right"
              onClick={scrollRight}
            >
              &gt;
            </Button>
          </Box>
          <Typography variant="body1" color="textSecondary" gutterBottom sx={{ mb: 4, mt: 4 }}>
          P.S. Use pictures with a clear subject and a clean background for the best results!          
          </Typography>
        </Box>
      </Box>

      {/* New About Us Section */}
      <Box className="about-us-box" width="140%" p={4} borderRadius="10px" boxShadow={3} sx={{ backgroundColor: '#ffffff', mt: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ mb: 1, textAlign: 'center', fontFamily: 'CupCakes, sans-serif' }}
          >
            About Us
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '100%', mt: 2 }}>
            <Box component="img" src="/example_images/twopals.jpg" alt="About Us" 
              sx={{ 
                width: '220px',  // Increased width for better visibility
                height: 'auto', 
                borderRadius: '10px', 
                mr: 3,
                mb: 2,  // Adds a bit of space below the image 
                ml: -2,  // Moves the image closer to the left
                mt: -2   // Moves the image closer to the top
              }} 
            />
            <Box sx={{ textAlign: 'left', maxWidth: '70%' }}>
              <Typography variant="body1" sx={{ color: '#666', mb: 2 }}>
                Hi there! We are Maarten Stork and Jip van der Kroef, graduates in Artificial Intelligence from the VU University in Amsterdam. This project started out as just an idea on paper; turning photo’s into coloring pages. We found that no service was able to properly do this, while we were so sure that it was possible! So, we spent our summer months experimenting, building, and refining this product until we consistantly got results that we were proud of!
              </Typography>
              <Typography variant="body1" sx={{ color: '#666', mb: 2 }}>
                We're currently in the early stages of our project and can't wait to see how people will use it. We envision people turning pictures of their family, friends, holidays, pets and houses into coloring pages. We would love to see all of the creative ways our coloring pages will hopefully be used! For inspiration, you could consider using paint to fill them in or even creating a custom coloring book as a unique and thoughtful gift.
              </Typography>
              <Typography variant="body1" sx={{ color: '#666', mb: 2 }}>
              We're incredibly proud of what we've built and are very happy with all the feedback we can get. If you have any issues, improvements, or collaboration ideas please let us know via the “Contact Us” form in the top right of this page! And we would love to see your finished artworks - you can always send them to 'mycoloringpic@gmail.com' 😊
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Loading Bubble */}
      {showLoadingBubble && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            backgroundColor: '#4a90e2',
            color: '#ffffff',
            padding: '10px 20px',
            borderRadius: '10px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            zIndex: 1500,
            transition: 'opacity 0.5s ease',
          }}
        >
          <CircularProgress size={16} sx={{ color: '#ffffff', marginRight: '10px' }} />
          <Typography variant="body2" sx={{ fontFamily: 'Roboto, sans-serif' }}>
            Loading can take anywhere between 30 and 60 seconds!
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default LandingPage;
